<template>
  <div class="bg-dark rounded mb-2 p-4" id="upsell-card" v-if="!hideUpsell && planDescription.upsell">
    <div class="d-flex mt-1 mb-4">
      <div class="d-flex flex-column align-items-start justify-content-center">
        <h6 
          v-if="planDescription.upsell?.special"
          class="text-start fw-bold"
          style="color: rgb(9, 227, 151);font-size:0.9em;"
        >
          {{ planDescription.upsell?.specialHeading }}
        </h6>
        <h5 class="text-white text-start fs-18">{{ planDescription.upsell?.heading }}</h5>
        <div class="d-block text-start small">
          <span style="color:#FE0086;"> 
            {{ planDescription.upsell.subHeading }} 
            <span class="text-white"> {{ planDescription.upsell.price }} </span>
            <a
              v-if="showServerInfoLink"
              href="https://speedify.com/for-teams/dedicated-vpn-servers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more »
            </a>
          </span>
        </div> 
      </div>
      <div class="overflow-visible upsell-image-container">
        <img v-if="showServerInfoLink" :src="speedifyMaintenance" class="upsell-image" alt="Speedify Dedicated Servers" />
        <img v-else-if="planDescription.upsell?.special" :src="speedifySpecial" class="upsell-image" alt="Speedify Special Deal" />
        <img v-else-if="planDescription.upsell.code.includes('families')" :src="speedifyFamily" class="upsell-image" alt="Speedify Family" />
        <img v-else :src="speedifyGuy" class="upsell-image" alt="Speedify Character" /> 
      </div>
    </div>
    <button type="button" class="btn-primary w-100" @click="handleSwitchClick">
      <strong class="text-white">{{ planDescription.upsell?.buttonText }}</strong>
    </button>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useCheckoutStore } from '@/stores/checkout';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute} from 'vue-router';
import speedifyGuy from '@/assets/images/cool-guy-sitting-speedify.png';
import speedifyMaintenance from '@/assets/images/speedify-maintenance.png';
import speedifyFamily from '@/assets/images/speedify-family.png';
import speedifySpecial from '@/assets/images/speedify-special.png';

const checkoutStore = useCheckoutStore();
const { checkoutData } = checkoutStore;
// TODO: idk why planDescription must be a ref here
const { planDescription } = storeToRefs(checkoutStore);
const router = useRouter();
const route = useRoute();

const hideUpsell = computed(() => {
  return (route.query.servers !== undefined && checkoutData.plan.includes('team')) || checkoutData.upsold;
});

const showServerInfoLink = computed(() => {
  return planDescription.value.upsell?.code.includes('server');
});

const handleSwitchClick = () => {
  const upsellProduct = planDescription.value.upsell?.code;
  const upsellCoupon = planDescription.value.upsell?.coupon;
  if (upsellProduct.includes('server')){
    router.replace({ 
      query: {
        plan: checkoutData.plan,
        quantity: checkoutData.quantity,
        servers: 1,
        'utm_source': 'cart',
        'utm_medium': 'upsell',
        'utm_campaign': 'server',
        'utm_content': checkoutData.plan,
      }
    });
  } else {
    router.replace({
      query: {
        plan: upsellProduct,
        ...(upsellCoupon && { coupon: upsellCoupon }),
        quantity: checkoutData.quantity,
        ...(route.query.servers && { servers: route.query.servers }),
        'utm_source': 'cart',
        'utm_medium': 'upsell',
        'utm_campaign': upsellProduct,
        'utm_content': checkoutData.plan,
      }
    });
    checkoutData.plan = upsellProduct;
    if (upsellCoupon) checkoutData.coupon.inputtedCode = upsellCoupon;
    checkoutData.upsold = true;
  }
};
</script>
<style scoped>
#upsell-card {
  padding: 12px 12px 12px 12px;
  overflow: hidden;
}
.upsell-image {
  /*max-height: 25%;
  max-width: 25%;*/
  height: 90px;
  align-self: center;
  justify-self: left;
}
.upsell-image-container {
  max-width: 30%;
}
</style>
